<template>
  <footer>
  <div class="container-fluid headerFooter">
    <div  class="container">
      <!--<div class="row pt-2  justify-content-center">
          <div class="col-2"></div>
          <div class="col-2"></div>
          <div class="col-4 title">
            Unsere Standortorte
          </div>
      </div> -->   	
      <div class="row pb-2 justify-content-center">
          
        
          <div class="col-sm-12 col-lg-4">
              <div class="row">
                  <div class="col-6 subTitle">
                      <a class="nav-link active" aria-current="page" href="#">Impressum</a>
                      <a class="nav-link active" aria-current="page" href="#">AGB</a>
                      <a class="nav-link active" aria-current="page" href="#">Datenschutz</a>
                    

                  </div>
                  <div class="col-6 subTitle">
                      <a class="nav-link active" aria-current="page" href="#">Karriere</a>
                      <a class="nav-link active" aria-current="page" href="#">Kontakt</a> 
                      <a class="nav-link active" aria-current="page" href="#">Anfahrt</a>         
                      
                    
                  </div>
            </div>  
          </div>
          <div class="col-sm-12 col-lg-4">
           
            <div class="row">
                <div class="col-6 subTitle">
                      Langenhof 1<br>
                      26160 Bad Ziwschenahn<br>
                      Tel.: 04403 9165255<br>
                      Fax: 04403 9165257
                </div>
                <div class="col-6 subTitle">
                      Bahnhofplatz 4<br>
                      26122 Oldenburg<br>
                      Tel.: 0441 77707829<br>
                      Fax: 0441 77707830
                </div>

              </div> 
          </div> 
    </div>
  </div>
</div>
<div class="container-fluid Footer">
    <div  class="container ">
      <div class="row">
          <div class="col-12">© 2024 Beratung und Coaching beim Centrum für Integration und Bildung e.V. </div>
      </div>
    </div>
</div>  

</footer>
</template>



<style scoped>
  .headerFooter{
       
        background-color: rgb(216, 244, 252);
    }

    .Footer{
       
       background-color: rgb(64, 155, 180);
       font-size: 0.7vw;
       color: white;
       padding: 5px;
       text-align: center;
   }


    .title{
        padding:0px;
        font-size: 0.8vw;
        
       
      
       
    }

    .subTitle {
        padding:5px;
        font-size: 12px;
       
    }


     nav {
        padding: 30px;
    }
    
    nav a {
        font-weight: bold;
        color: #2c3e50;
    }
    
    nav a.router-link-exact-active {
        color: #42b983;
    }
    
    
    
    
    
</style>