<template>
    <header>

        
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-4">
                        <img src="../assets/CIB-Logo-Text.png"  id="logocib" />
                    </div>        
                    <div class="col-md-12 col-lg-8 text-md-start text-lg-end kontakt mt-3">
                        <div class="row">       
                            <div class="col-6">
                                            
                                <img class="phoneImage" src="../assets/Phone-contact-us-1908762_1280.png" alt="Phone-contact-us-1908762_1280" />
                                    +49 4403-9165256
                                            
                            </div>
                            <div class="col-6">
                                <img class="phoneImage" src="../assets/Mail-contact-us-1908762_1280.png" alt="Mail-contact-us-1908762_1280" />    
                                    info@cib-ol.eu
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div class="container-fluid headerFooter mt-3">
            <div class="container">          
                <div class="row">
                    <div class="col-12 bg">
                        <div class="container-fluid" id="menue_format">
                            <nav class="navbar navbar-expand-lg">
                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                            
                                <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
                                <ul class="navbar-nav ms-auto mb-2 mb-lg-0 justify-content-end">
                                    <li class="nav-item">
                                        <a class="nav-link active" aria-current="page" href="#">Home</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link active" aria-current="page" href="#">Maßnahmen</a>
                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Standorte
                                        </a>
                                        <ul class="dropdown-menu">
                                            <li><a class="dropdown-item" href="#">Bad Zwischenahn</a></li>
                                            <li><a class="dropdown-item" href="#">Oldenburg</a></li>
                                        </ul>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link active" aria-current="page" href="#">Wir über uns</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link active" aria-current="page" href="#">Wir für Sie</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link active" aria-current="page" href="#">Kontakt</a>
                                    </li>
                                </ul>
                                </div>
                            </nav>
                        </div>
                    </div>   
                </div>
            </div>  
        </div> 
           
            
       
            
       
    </header>
</template>

<script>
    export default {
        name: 'Header',
    }
</script>



<style scoped>

    .headerFooter{
        padding: 0px;
        margin: 0px;
        background-color: rgb(216, 244, 252);
    }

    .phoneImage{
        height: 50px;
    }

    .emailImage{
        height: 50px;
    }

    .kontakt{
        font-size: 15px;
    }

    .right-side{
        
        display: flex; 
        justify-content: space-between;
        gap: 8px;
        font-size: 1vw;
       
        
    }
    
    .vertical-text{
        /*font-size: 1vw;
       
        vertical-align: middle;*/
    }


    .navbarTogglerDemo03{
        padding: 0px;

    }

    menue_format{
    
        padding: 0px;
    }

    nav {
        /*padding: 30px;*/
        padding: 0%;
        padding-top: 10px;
    }
    nav li{
        padding:20px;
    }
    
    nav a {
        font-weight:lighter;
        color: rgba(0, 0, 0, 0.5);
        font-size: 20px;;
    }
    
    nav a.router-link-exact-active {
        color: #42b983;
    }
    
    #logocib{
        width: 100%;
    }
    
   
    
   
    
</style>