<template>
  <Header></Header>  

  <router-view/>

  <Footer></Footer>

</template>


<script>
  
  import Header from './components/Header.vue'
  import Footer from './components/Footer.vue'
 
  export default {
      components: {
        Header,
        Footer
      }
  }


  
  
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 14px;
  text-align: justify;
}

body {
  padding-top: 20px;
}

</style>
