<template>
  <div class="home container">
      <div class="row mb-5">
          <div class="col-12 center-block" id="puzzle">
            <div class="row">
              <img src="../assets/Header_Faces.png" class="image-fluit" id="imgFaces" alt="Header_Faces">
              <img src="../assets/Header_Faces_Title.png" class="image-fluit" id="imgFacesTitle" alt="Header_Faces_Title">
              <!----><div class="row">
                <div class="col-12 main">
                  <div class="row">
                    <div class="col-6"></div>
                    <div class="col-6" id="kompetenz">
                          <!-- Kompetenz in Integration und Bildung<br>
                          dafür steht das<br><span id="spancib-blue">Centrum für Integration und</span><br>
                          <span id="spancib-red">Bildung e.V.</span>-->
                    </div>
                  </div>
                </div>  
                
              </div>
            </div>
          </div>
      </div>
      <div class="row">
        <div class="col-12 title1 text-center">
            Herzlich willkommen bei dem Centrum für Qualifizierung im Ammerland,<br> Ihrem kompetenten Bildungsträger!<br/><br/>
        </div>
      </div>
     
      
      <div class="row h-100 mb-5" id="cibVorstellung">
          <div class="col-12 col-lg-4">
              Unser Ziel ist die Förderung der beruflichen Qualifizierung aus der Erkenntnis, dass der beruflichen Bildung eine entscheidende Rolle bei der Zuteilung sozialer und beruflicher Chancen zukommt. Dies wird insbesondere verwirklicht durch die Entwicklung, Durchführung und Betreuung von geeigneten Bildungsmaßnahmen zur Qualifizierung und Befähigung unserer Teilnehmer zur Wiedereingliederung in den 1. Arbeitsmarkt.
          </div>    
          <div class="col-12 col-lg-4 mt-1 mb-1" id="contentVertical">
           <img src="../assets/consulting-2045471_1280.jpg" class="image-fluit" alt="consulting-2045471_1280" style="width: 100%;">
             <!--<img src="../assets/teach-1968076_1280.jpg" class="image-fluit" alt=" teach-1968076_1280" style="width: 100%;">-->
          </div>
          <div class="col-12 col-lg-4">
            Unsere örtlich-regionale Präsenz und unser breitgefächertes Angebot sind Nachweis unserer engagierten, kundenorientierten und qualitativ hochwertigen Bildungsarbeit.
                Berufliche Bildung ist eine unverzichtbare Chance für diejenigen, die Individuelle Handlungsspielräume erweitern, ihren beruflichen und sozialen Aufstieg planen, ihren sozialen Stand durch Kompetenzerweiterung erhalten, Qualifikationsdefizite beheben oder ihre Vermittlungschancen am Arbeitsmarkt verbessern wollen.  
          </div>     
      </div>


     





      <div class="row">
        <div class="col-12 mt-5">
          <ProjektItem></ProjektItem>       
        </div>
      </div>

      <div class="row justify-content-center">
        <div class=" col-sm-6 col-lg-5 mb-5 text-center title2">
            <span style="color:#00C;">Das Centrum für Integration</span><span style="color:#FF0000;"> und Bildung e.V</span><span style="color:#00C;">,<br>ist ein nach <b>AZAV zertifizierter Bildungsträger</b></span>
        </div>
        <div class="col-sm-6 col-lg-1 mb-5">
            <img class="logo" src="../assets/LogoGutCert.png" alt="LogoGutCert" />
        </div> 
          
    </div>

   
    
    <!--<img alt="Vue logo" src="../assets/logo.png">-->
    <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->
  </div>
</template>

<script>
import ProjektItem from '../components/ProjektItem.vue';
// @ is an alias to /src
//import Header from '@/components/Header.vue';


export default {
  name: 'HomeView',
  components: {
    ProjektItem
    //Header
  }
}
</script>

<style>
    #kompetenz{
      z-index: 6;
      font-size:1.5em;
    }

    .home{
      position:relative;
    }
    .main{
      position:absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      color: black;
      text-align: center;
      margin-top: 5%;

    }
    
   


    #imgFaces{
     
      z-index: 0;

    }

    #imgFacesTitle{
      position:absolute;
      z-index: 5;

    }
    #cibVorstellung{
      padding: 5px;;
      background-color: rgb(216, 244, 252);
      font-size: 15px;
      
    }

    .title1{
      font-size: 22px;
      color:#354399;
      
    }

    .title2{
      font-size: 20px;
      
    }

    #spancib-blue{
        color:#354399;
    }
    
    #spancib-red{
        color:red;
    }

    #contentVertical{
      display: flex;
      align-items: center;
    }


    .logo{

      display:block; 
      width: 100px;
      margin:0 auto;

    }
</style>