<template>
    <div class="row justify-content-center">
        <div class="col-11">
            <div class="row justify-content-center">
                <div class="col-10 titleProjekte">Unser aktuelles Angebot</div>
                <!--<div class="col-3 titleProjekte"></div>
                <div class="col-3 titleProjekte"></div>-->
            </div>    
        </div>
    </div>    
    
    
    <div class="row justify-content-center">
        <div class="col-11">
            <div class="row justify-content-center">    
                <div class="col-sm-12 col-lg-3 projektItemContainer shadow-lg  mb-5 rounded">
                    <div class="row">
                        <div class="col-12">        
                            <img class="projektImage"  src="../assets/students-8575444_1280.png" alt="students-8575444_1280" />
                            <div class="title">Werkakademie<br>&nbsp;"Work First"<br>&nbsp;</div>
                            <div class="subTitle">Es ist Ihr Job,<br>Arbeit zu finden<br>&nbsp;</div>
                            <div class="subTitle"><b>Gefördert durch das</b></div>
                            <img class="logo"  src="../assets/jc_ol.png" alt="jc_ol" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8 kursLink">
                            Zum Kurs >
                        </div>
                    </div>

                </div>
               
                <div class="col-sm-12 col-lg-3 projektItemContainer shadow-lg  mb-5 rounded">
                    <div class="row">
                        <div class="col-12">
                            <img class="projektImage" src="../assets/man-2562325_1280.jpg" alt="man-2562325_1280" />
                            <div class="title">"Coaching für Menschen<br> mit Migrationshintergrund (CfMM)"</div>
                            <div class="subTitle">Ein berufsbezogenes<br>
                            Coaching mit ganzheitlicher Ausrichtung<br>&nbsp;</div>
                            <div class="subTitle"><b>Gefördert durch das</b></div>
                            <img class="logo" src="../assets/Logo-Jobcenter_Landkreis_Ammerland_4K.jpg" alt="Logo-Jobcenter_Landkreis_Ammerland" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8 kursLink">
                            Zum Kurs >
                        </div>
                    </div>
                    
                </div>
                <div class="col-sm-12 col-lg-3 projektItemContainer shadow-lg  mb-5 rounded">
                    <div class="row">
                        <div class="col-12">
                            <img class="projektImage" src="../assets/man-2562325_1280.jpg" alt="man-2562325_1280" />
                            <div class="title">Коучинг для людей с миграционным прошлым (CfMM)</div>
                            <div class="subTitle">Коучинг, связанный с работой, с целостным подходом<br>&nbsp;</div>
                            <div class="subTitle"><b>Финансируется за счет этого</b></div>
                            <img class="logo" src="../assets/Logo-Jobcenter_Landkreis_Ammerland_4K.jpg" alt="Logo-Jobcenter_Landkreis_Ammerland" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8 kursLink">
                            Zum Kurs >
                        </div>
                    </div>
                     
                </div>
                <div class="col-sm-12 col-lg-3 projektItemContainer shadow-lg  mb-5 rounded">
                    <div class="row">
                        <div class="col-12">
                            <img class="projektImage"  src="../assets/Ballon.png" alt="Ballon" />
                            <div class="title">"MEGA"<br>&nbsp;<br>&nbsp;</div>
                            <div class="subTitle">Menschen die Eingliederung Gestalten und Aktivieren"<br>
                             Coaching mit ganzheitlicher Ausrichtung<br>&nbsp;</div>
                             <div class="subTitle"><b>Gefördert durch das</b></div>
                             <img class="logo" src="../assets/Logo-Jobcenter_Landkreis_Ammerland_4K.jpg" alt="Logo-Jobcenter_Landkreis_Ammerland" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8 kursLink">
                            Zum Kurs >
                        </div>
                    </div>
                    
                </div>
                <div class="col-sm-12 col-lg-3 projektItemContainer shadow-lg  mb-5 rounded">
                    <div class="row">
                        <div class="col-12">
                            <img class="projektImage" src="../assets/bulletin-board.jpg" alt="bulletin-board" />
                            <div class="title">"Coaching zum<br>Berufseinstieg - hybrid"<br>&nbsp;</div>
                             <div class="subTitle">Das berufsbegleitende<br>Coachingmit ganzheitlicher<br>Ausrichtung<br>&nbsp;</div>
                            <div class="subTitle"><b>Gefördert durch das</b></div>
                            <img class="logo" src="../assets/Logo-Jobcenter_Landkreis_Ammerland_4K.jpg" alt="Logo-Jobcenter_Landkreis_Ammerland" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8 kursLink">
                            Zum Kurs >
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>    
    </div>


    










   
    
    
</template>




<script>
export default {
    name: 'ProjektItem',
    /*data() {
        return{
            imageMEGA: '../assets/Ballon.png',
            label: '/"MEGA"/ - Menschen die Eingliederung /"Gestalten und Aktivieren/""',
            attributes: 'Coaching mit ganzheitlicher Ausrichtung' 
        }
    }*/
}
</script>

<style scoped>

    .row{
        margin-bottom: 10px;; 
    }
    .projektItemContainer{
        
        margin: 30px;
        padding:0px;
        background-color: antiquewhite;
        
       
        text-align: left;
    }


    .titleProjekte{
        
        margin: 30px;
        margin-bottom: 0px;
        padding:0px;
        text-align: left;
        color: rgba(0, 0, 0, 0.5);
        font-size: 20px;
        border-bottom: 4px solid #409bb4;


    }

    
    .projektItemBack{


    }

    .title{
        padding:5px;
        font-size: 18px;
        text-align: center;
      
       
    }

    .subTitle {
        padding:5px;
        font-size: 16px;
        text-align: center;
        color: rgba(0, 0, 0, 0.6);
    }

   

    .projektImage{
        width: 100%;

    }

    .logo{

        display:block; 
        width: 50%;
        margin:0 auto;

    }

    .right-side{
        display: flex;
        flex-direction:column;
        justify-content: space-between;
        gap: 8px;
    }


    .kursLink{
        display: block;
        
        margin:0 auto;
        text-align: center;
        background-color: rgb(64, 155, 180);
        font-size: 16px;
        color: aliceblue;
    }
</style>